
canvas{
border-radius: 10px;
border: 1px solid #000!important;

}
video{
    border-radius: 10px;
}
body{
    -webkit-font-smoothing: unset;
}
.keypadcssnum{
    font-size: 5vmin!important;
}
.keypadcsfo{
    font-size: 4.5vmin!important;  
}
.slider-wrapper {
    border-radius: 9px!important;
}

/* .thanksPage .bp5-dialog-body-scroll-container{
    height: 90vh!important;
} */
.keypadcss:disabled {
    cursor: not-allowed;
    background: rgb(136, 136, 136)!important;
    color: white!important;
  }   
.nextbuttoncss:disabled {
    cursor: not-allowed;
    background: rgb(136, 136, 136)!important;
    color: white!important;
  } 
  
.continuebtn:disabled {
   
    cursor: not-allowed;
    background: rgb(136, 136, 136);
}

.admincontrols{
    position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding-top: 2rem;
  opacity:1;
  
}
.dob-label-color{
    color: rgb(252, 173, 20);
}


.positionAbsolute{
    position: absolute;
    z-index: -1000;
    /* left: -5000px; */
}

.picksList td{
    padding: 10px;
    border:1px solid #e6e5e5
}
.tac{
    font-size: 1.5rem;
    color: rgb(226, 50, 41);
    text-align: center;
}
.css-6p4mt6::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgb(106, 107, 111);
    box-shadow: black 0px 0px 0px 2px;
    width: 24px;
}
.css-6p4mt6::-webkit-scrollbar {
    width: 24px;
}
.css-19ogqkc {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 80vw;
    height: 80vh;
    padding: 2rem;
    margin: auto;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(68, 68, 68);
    border-radius: 12px;
    box-shadow: rgb(68, 68, 68) 1px 2px 1px 1px;
    z-index: 10;
}


@media only screen and (min-device-width :768px) and (max-device-width : 1000px) {


    .kioskUiInAdvFile{
        position: absolute;
        left: 10%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .kioskUiOutAdvFile{
        position: absolute;
        right: 10%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .kioskUiInAdvFilesignature{
        position: absolute;
        left: 5%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .kioskUiOutAdvFilesignature{
        position: absolute;
        right: 5%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .thnaksPageMsg{
        font-size: 2rem;
        text-align: center;
        text-shadow: black 1px 2px 0px;
        max-width: 50vw;
        margin: auto;
    }
    .css-6p4mt6{
        overflow-y: scroll;
        border: 2px solid rgb(68, 68, 68);
        border-radius: 12px;
        padding: 0.5rem;
        max-height: 55vh;
        margin-bottom: 1rem;
    }

    .css-q3non2 {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 1vw;
        text-shadow: black 1px 1px 1px;
        background: rgb(232, 165, 48);
        border: 2px solid black;
        border-radius: 12px;
        outline: none;
        box-shadow: black 1px 2px 1px 1px;
        margin: 10px;
        padding: 0.25em 0.5em;
        color: white;
        transform: translate(0px, 0px);
    }

    .picksList{
        width: 100%;
        font-size: x-large;
        text-align: justify;
        font-weight: 600;
        border-collapse: collapse;
    }
    .mobilegrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* width: 100%; */
        margin: auto;
        margin-bottom: auto;
        justify-content: center;
        margin-top: 11px;
    }

    .selectgroup{
    display: flex;
    margin: auto;
    width: 60%;
    margin-bottom: 17px;
    }
    
    .selectgroup select{
        position: relative;
        font-family: inherit!important;
        color: inherit;
        line-height: inherit!important;
        appearance: none;
        width: 100%;
        border-radius: 12px;
        border: 2px solid black;
        color: rgb(20, 20, 20);
        padding: 0.5em;
        transition: opacity 250ms ease-in-out 0s;
        background-color: white;
        margin-top: 10px;
        font-size: 2rem!important;
        min-width: 6em;
    }
    .selectgroup label{
        margin: 0px 0.5rem!important;
        text-shadow: black 1px 2px 0px;
        font-size: 2.5rem;
        font-weight: bold;
        padding-left: 0.5rem;
    }
    .centerDiv{
        width: 100%;
        /* margin: auto; */
        justify-content: center;
    }

    .centerDiv2{
        width: 100%;
        /* margin: auto; */
    }

    .titleforwilbert{
        font-size: 2rem;
    }
    .centerDivName{
        width: 50vw;        
        margin: auto;
        justify-content: space-around;
    }
    
    .keypadcss{
        display: flex!important;
        -webkit-box-pack: center!important;
        justify-content: center!important;
        -webkit-box-align: center!important;
        align-items: center!important;
        font-size: 4.5vmin;
        text-shadow: black 1px 1px 1px!important;       
        border: 2px solid black!important;
        border-radius: 12px!important;
        outline: none!important;
        box-shadow: black 1px 2px 1px 1px!important;
        margin: 0.5vmin!important;
        padding: 0.25em 0.5em!important;
        /* color: white!important; */
        transform: translate(0px, 0px)!important;
        font-family:revert!important;
    }
    .continuecontainerleft{
        position: absolute;
        bottom: 31%;
        width: 27%;
        left:2%;
        text-align: left;
        margin: auto;
    }

    .continuecontainerright{
        position: absolute;
        bottom: 31%;
        width: 27%;
        right:2%;
        text-align: left;
        margin: auto;
    }

    .continuecontainerright p{
       overflow: auto;
    }

    .continuebtn {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 2rem;
        text-shadow: black 1px 1px 1px;
        border: 2px solid black;
        border-radius: 12px;
        outline: none;
        box-shadow: black 1px 2px 1px 1px;
        margin:auto;
        padding: 0.25em 0.5em;
        color: white;
        transform: translate(0px, 0px);
    }

    .inputgroup{
        justify-content: center;
        display: flex;
    }
    .focusInput{
        box-shadow: black 1px 2px 1px 1px, rgb(43, 60, 139) 1px 2px 0px 6px, white 1px 2px 0px 5px;
     }
.nameInput{
    border-radius: 12px;
    border: 2px solid black;
    /* box-shadow: black 1px 2px 1px 1px; */
    outline: none;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    transition: box-shadow 250ms ease 0s;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-family: monospace;
    font-size: 1.5rem;
    height: 4rem;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: auto;
    text-align: left;
    width: 14rem;
}

.EmailInput{
    border-radius: 12px;
    border: 2px solid black;
    box-shadow: black 1px 2px 1px 1px;
    outline: none;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    transition: box-shadow 250ms ease 0s;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-family: monospace;
    font-size: 1.5rem;
    height: 5rem;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: auto;
    text-align: left;
    width: 25rem;
}

.css-1id8coj {
    background-color: white;
    width: 70vw;
    height: 50%;
    margin: auto;
    overflow-y: auto;
    padding: 1rem;
    border: 2px solid black;
    border-radius: 12px;
    box-shadow: black 1px 2px 1px 1px;
    margin: 63px auto 30px auto;
}

.css-z0ieus {
    font-size: 1.5rem;
    color: black;
    text-align: center;
    font-weight: bold;
    text-shadow: none;
    margin: 1rem;
}

.css-1ldd5ho {
    font-size: 2rem!important;
    text-shadow: black 1px 2px 0px!important;
    color: rgb(252, 173, 20);
    margin-right: 2rem!important;
    opacity: 1;
    transition: opacity 250ms ease-in-out 0s;
    padding: 14px;
}

.css-17vg6hn {
    position: relative;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    color: inherit;
    line-height: inherit;
    appearance: none;
    min-width: 12em;
    width: 100%;
    background-color: white;
    margin: 0px;
    border-radius: 12px;
    border: 2px solid black;
    padding: 0.5em;
    transition: opacity 250ms ease-in-out 0s;
}
    }



@media only screen and (min-device-width :1000px) and (max-device-width : 4000px) {


    .kioskUiInAdvFile{
        position: absolute;
        left: 10%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .kioskUiOutAdvFile{
        position: absolute;
        right: 10%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .kioskUiInAdvFilesignature{
        position: absolute;
        left: 5%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .kioskUiOutAdvFilesignature{
        position: absolute;
        right: 5%;
        top: 25%;
        width: 25vw;
        height: 30vh;
    }

    .thnaksPageMsg{
        font-size: 2rem;
        text-align: center;
        text-shadow: black 1px 2px 0px;
        max-width: 50vw;
        margin: auto;
    }
    .css-6p4mt6{
        overflow-y: scroll;
        border: 2px solid rgb(68, 68, 68);
        border-radius: 12px;
        padding: 0.5rem;
        max-height: 55vh;
        margin-bottom: 1rem;
    }

    .css-q3non2 {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 1vw;
        text-shadow: black 1px 1px 1px;
        background: rgb(232, 165, 48);
        border: 2px solid black;
        border-radius: 12px;
        outline: none;
        box-shadow: black 1px 2px 1px 1px;
        margin: 10px;
        padding: 0.25em 0.5em;
        color: white;
        transform: translate(0px, 0px);
    }

    .picksList{
        width: 100%;
        font-size: x-large;
        text-align: justify;
        font-weight: 600;
        border-collapse: collapse;
    }
    .mobilegrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* width: 100%; */
        margin: auto;
        margin-bottom: auto;
        justify-content: center;
        margin-top: 11px;
    }

    .selectgroup{
    display: flex;
    margin: auto;
    width: 60%;
    margin-bottom: 17px;
    }
    
    .selectgroup select{
        position: relative;
        font-family: inherit!important;
        color: inherit;
        line-height: inherit!important;
        appearance: none;
        width: 100%;
        border-radius: 12px;
        border: 2px solid black;
        color: rgb(20, 20, 20);
        padding: 0.5em;
        transition: opacity 250ms ease-in-out 0s;
        background-color: white;
        margin-top: 10px;
        font-size: 2rem!important;
        min-width: 6em;
    }
    .selectgroup label{
        margin: 0px 0.5rem!important;
        text-shadow: black 1px 2px 0px;
        font-size: 2.5rem;
        font-weight: bold;
        padding-left: 0.5rem;
    }
    .centerDiv{
        width: 100%;
        /* margin: auto; */
        justify-content: center;
    }

    .centerDiv2{
        width: 100%;
        /* margin: auto; */
    }

    .titleforwilbert{
        font-size: 2rem;
    }
    .centerDivName{
        width: 50vw;        
        margin: auto;
        justify-content: space-around;
    }
    
    .keypadcss{
        display: flex!important;
        -webkit-box-pack: center!important;
        justify-content: center!important;
        -webkit-box-align: center!important;
        align-items: center!important;
        font-size: 4.5vmin;
        text-shadow: black 1px 1px 1px!important;       
        border: 2px solid black!important;
        border-radius: 12px!important;
        outline: none!important;
        box-shadow: black 1px 2px 1px 1px!important;
        margin: 0.5vmin!important;
        padding: 0.25em 0.5em!important;
        /* color: white!important; */
        transform: translate(0px, 0px)!important;
        font-family:revert!important;
    }
    .continuecontainerleft{
        position: absolute;
        bottom: 6%;
        width: 27%;
        left:9%;
        text-align: left;
        margin: auto;
    }

    .continuecontainerright{
        position: absolute;
        bottom: 6%;
        width: 27%;
        right:9%;
        text-align: left;
        margin: auto;
    }

    .continuebtn {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 2rem;
        text-shadow: black 1px 1px 1px;
        border: 2px solid black;
        border-radius: 12px;
        outline: none;
        box-shadow: black 1px 2px 1px 1px;
        margin:auto;
        padding: 0.25em 0.5em;
        color: white;
        transform: translate(0px, 0px);
    }

    .inputgroup{
        justify-content: center;
        display: flex;
    }
    .focusInput{
        box-shadow: black 1px 2px 1px 1px, rgb(43, 60, 139) 1px 2px 0px 6px, white 1px 2px 0px 5px;
     }
.nameInput{
    border-radius: 12px;
    border: 2px solid black;
    /* box-shadow: black 1px 2px 1px 1px; */
    outline: none;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    transition: box-shadow 250ms ease 0s;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-family: monospace;
    font-size: 1.5rem;
    height: 4rem;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: auto;
    text-align: left;
    width: 14rem;
}

.EmailInput{
    border-radius: 12px;
    border: 2px solid black;
    box-shadow: black 1px 2px 1px 1px;
    outline: none;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    transition: box-shadow 250ms ease 0s;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-family: monospace;
    font-size: 1.5rem;
    height: 5rem;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: auto;
    text-align: left;
    width: 25rem;
}

.css-1id8coj {
    background-color: white;
    width: 70vw;
    height: 50%;
    margin: auto;
    overflow-y: auto;
    padding: 1rem;
    border: 2px solid black;
    border-radius: 12px;
    box-shadow: black 1px 2px 1px 1px;
    margin: 63px auto 30px auto;
}

.css-z0ieus {
    font-size: 1.5rem;
    color: black;
    text-align: center;
    font-weight: bold;
    text-shadow: none;
    margin: 1rem;
}

.css-1ldd5ho {
    font-size: 2rem!important;
    text-shadow: black 1px 2px 0px!important;
    color: rgb(252, 173, 20);
    margin-right: 2rem!important;
    opacity: 1;
    transition: opacity 250ms ease-in-out 0s;
    padding: 14px;
}

.css-17vg6hn {
    position: relative;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    color: inherit;
    line-height: inherit;
    appearance: none;
    min-width: 12em;
    width: 100%;
    background-color: white;
    margin: 0px;
    border-radius: 12px;
    border: 2px solid black;
    padding: 0.5em;
    transition: opacity 250ms ease-in-out 0s;
}
    }


    @media only screen and (min-device-width : 480px) and (max-device-width : 768px) {
        .kioskUiOutAdvFile{
            position: absolute;
            right: 10%;
            top: 25%;
            width: 25vw;
            height: 30vh;
        }
        .kioskUiInAdvFile{
            position: absolute;
            left: 10%;
            top: 25%;
            width: 25vw;
            height: 30vh;
        }
    
        .kioskUiOutAdvFilesignature{
            position: absolute;
            right: 5%;
            top: 25%;
            width: 25vw;
            height: 30vh;
        }
        .kioskUiInAdvFilesignature{
            position: absolute;
            left: 5%;
            top: 25%;
            width: 25vw;
            height: 30vh;
        }
        .thnaksPageMsg{
            font-size: 1.7rem;
            text-align: center;
            text-shadow: black 1px 2px 0px;
            max-width: 50vw;
            margin: auto;
        }
    
        .css-6p4mt6 {
            overflow-y: scroll;
            border: 2px solid rgb(68, 68, 68);
            border-radius: 12px;
            padding: 0.5rem;
            max-height: 55vh;
            margin-bottom: 1rem;
        }
        .css-q3non2 {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            font-size: 1vw;
            text-shadow: black 1px 1px 1px;
            background: rgb(232, 165, 48);
            border: 2px solid black;
            border-radius: 12px;
            outline: none;
            box-shadow: black 1px 2px 1px 1px;
            margin: 10px;
            padding: 0.25em 0.5em;
            color: white;
            transform: translate(0px, 0px);
        }
    
        .picksList{
            width: 100%;
            font-size: x-large;
            text-align: justify;
            font-weight: 600;
            border-collapse: collapse;
        }
        .mobilegrid{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            /* width: 100%; */
            margin: auto;
            margin-bottom: auto;
            justify-content: center;
            margin-top: 10px;
        }
        
        .selectgroup{
            display: flex;
            margin: auto;
            width: 80%;
            margin-bottom: 17px;
            }
            
        .selectgroup select{
                position: relative;
                font-family: inherit!important;
                color: inherit;
                line-height: inherit!important;
                appearance: none;
                width: 100%;
                border-radius: 12px;
                border: 2px solid black;
                padding: 0.5em;
                transition: opacity 250ms ease-in-out 0s;
                background-color: white;
                color: rgb(20, 20, 20);
                font-size: 2rem!important;
                min-width: 6em;
                margin-top: 10px;
            }
            .selectgroup label{
                margin: 0px 0.5rem!important;
                text-shadow: black 1px 2px 0px;
                font-size: 2.5rem;
                font-weight: bold;
                padding-left: 0.5rem;
            }
        
        
        /* .centerDiv{
            width: 50vw;
            margin: auto;
        } */
        .centerDiv{
            width: 100%;
            /* margin: auto; */
            justify-content: center;
        }
        .centerDivName{
            width: 50vw;
            margin: auto;
            justify-content: center;
        }
        
        .keypadcss{
            display: flex!important;
            -webkit-box-pack: center!important;
            justify-content: center!important;
            -webkit-box-align: center!important;
            align-items: center!important;
            font-size: 4.5vmin;
            text-shadow: black 1px 1px 1px!important;
            border: 2px solid black!important;
            border-radius: 12px!important;
            outline: none!important;
            box-shadow: black 1px 2px 1px 1px!important;
            margin: 0.5vmin!important;
            padding: 0.25em 0.5em!important;
            /* color: white!important; */
            transform: translate(0px, 0px)!important;
            font-family:revert!important;
        }
    
    
    
        .continuebtn {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            font-size: 2rem;
            text-shadow: black 1px 1px 1px;
            border: 2px solid black;
            border-radius: 12px;
            outline: none;
            box-shadow: black 1px 2px 1px 1px;
            margin:auto;
            margin-top: 10PX;
            padding: 0.25em 0.5em;
            color: white;
            transform: translate(0px, 0px);
        }
    
        .inputgroup{
            justify-content: center;
            display: flex;
        }
         .focusInput{
            box-shadow: black 1px 2px 1px 1px, rgb(43, 60, 139) 1px 2px 0px 6px, white 1px 2px 0px 5px;
         }
        .nameInput{
            border-radius: 12px;
            border: 2px solid black;
            /* box-shadow: black 1px 2px 1px 1px; */
            outline: none;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
            transition: box-shadow 250ms ease 0s;
            -webkit-box-align: center;
            align-items: center;
            box-sizing: border-box;
            display: flex;
            font-family: monospace;
            font-size: 1.5rem;
            height: 4rem;
            -webkit-box-pack: center;
            justify-content: center;
            pointer-events: auto;
            text-align: left;
            width: 14rem;
        }
    
        .EmailInput{
            border-radius: 12px;
            border: 2px solid black;
            box-shadow: black 1px 2px 1px 1px;
            outline: none;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
            transition: box-shadow 250ms ease 0s;
            -webkit-box-align: center;
            align-items: center;
            box-sizing: border-box;
            display: flex;
            font-family: monospace;
            font-size: 1.5rem;
            height: 5rem;
            -webkit-box-pack: center;
            justify-content: center;
            pointer-events: auto;
            text-align: left;
            width: 19rem;
        }
        .css-1id8coj {
            background-color: white;
            width: 70vw;
            height: 50%;
            margin: auto;
            overflow-y: auto;
            padding: 1rem;
            border: 2px solid black;
            border-radius: 12px;
            box-shadow: black 1px 2px 1px 1px;
            margin: 63px auto 30px auto;
        }
        .css-z0ieus {
            font-size: 1.5rem;
            color: black;
            text-align: center;
            font-weight: bold;
            text-shadow: none;
            margin: 1rem;
        }
    
        .css-1ldd5ho {
            font-size: 2rem!important;
            text-shadow: black 1px 2px 0px!important;
            color: rgb(252, 173, 20);
            margin-right: 2rem!important;
            opacity: 1;
            transition: opacity 250ms ease-in-out 0s;
            padding: 14px;
        }
    
        .css-17vg6hn {
            position: relative;
            font-size: 1.5rem;
           font-family: inherit;
           font-weight: 500;
            color: inherit;
            line-height: inherit;
            appearance: none;
            min-width: 12em;
            width: 100%;
            background-color: white;
            margin: 0px;
            border-radius: 12px;
            border: 2px solid black;
            padding: 0.5em;
            transition: opacity 250ms ease-in-out 0s;
        }
    
          }













 @media only screen and (min-device-width : 100px) and (max-device-width : 480px) {
            .kioskUiOutAdvFile{
                position: absolute;
                right: 10%;
                top: 25%;
                width: 25vw;
                height: 30vh;
            }
            .kioskUiInAdvFile{
                position: absolute;
                left: 10%;
                top: 25%;
                width: 25vw;
                height: 30vh;
            }
        
            .kioskUiOutAdvFilesignature{
                position: absolute;
                right: 5%;
                top: 25%;
                width: 25vw;
                height: 30vh;
            }
            .kioskUiInAdvFilesignature{
                position: absolute;
                left: 5%;
                top: 25%;
                width: 25vw;
                height: 30vh;
            }
            .thnaksPageMsg{
                font-size: 1.7rem;
                text-align: center;
                text-shadow: black 1px 2px 0px;
                max-width: 50vw;
                margin: auto;
            }
        
            .css-6p4mt6 {
                overflow-y: scroll;
                border: 2px solid rgb(68, 68, 68);
                border-radius: 12px;
                padding: 0.5rem;
                max-height: 55vh;
                margin-bottom: 1rem;
            }
            .css-q3non2 {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                font-size: 1vw;
                text-shadow: black 1px 1px 1px;
                background: rgb(232, 165, 48);
                border: 2px solid black;
                border-radius: 12px;
                outline: none;
                box-shadow: black 1px 2px 1px 1px;
                margin: 10px;
                padding: 0.25em 0.5em;
                color: white;
                transform: translate(0px, 0px);
            }
        
            .picksList{
                width: 100%;
                font-size: x-large;
                text-align: justify;
                font-weight: 600;
                border-collapse: collapse;
            }
            .mobilegrid{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                /* width: 100%; */
                margin: auto;
                margin-bottom: auto;
                justify-content: center;
                margin-top: 10px;
            }
            
            .selectgroup{
                display: flex;
                margin: auto;
                width: 80%;
                margin-bottom: 17px;
                }
                
            .selectgroup select{
                    position: relative;
                    font-family: inherit!important;
                    color: inherit;
                    line-height: inherit!important;
                    appearance: none;
                    width: 100%;
                    border-radius: 12px;
                    border: 2px solid black;
                    padding: 0.5em;
                    transition: opacity 250ms ease-in-out 0s;
                    background-color: white;
                    color: rgb(20, 20, 20);
                    font-size: 2rem!important;
                    min-width: 6em;
                    margin-top: 10px;
                }
                .selectgroup label{
                    margin: 0px 0.5rem!important;
                    text-shadow: black 1px 2px 0px;
                    font-size: 2.5rem;
                    font-weight: bold;
                    padding-left: 0.5rem;
                }
            
            
            /* .centerDiv{
                width: 50vw;
                margin: auto;
            } */
            .centerDiv{
                width: 100%;
                /* margin: auto; */
                justify-content: center;
            }
            .centerDivName{
                width: 50vw;
                margin: auto;
                justify-content: center;
            }
            
            .keypadcss{
                display: flex!important;
                -webkit-box-pack: center!important;
                justify-content: center!important;
                -webkit-box-align: center!important;
                align-items: center!important;
                font-size: 4.5vmin;
                text-shadow: black 1px 1px 1px!important;
                border: 2px solid black!important;
                border-radius: 12px!important;
                outline: none!important;
                box-shadow: black 1px 2px 1px 1px!important;
                margin: 0.5vmin!important;
                padding: 0.25em 0.5em!important;
                /* color: white!important; */
                transform: translate(0px, 0px)!important;
                font-family:revert!important;
            }
        
        
        
            .continuebtn {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                font-size: 2rem;
                text-shadow: black 1px 1px 1px;
                border: 2px solid black;
                border-radius: 12px;
                outline: none;
                box-shadow: black 1px 2px 1px 1px;
                margin:auto;
                margin-top: 10PX;
                padding: 0.25em 0.5em;
                color: white;
                transform: translate(0px, 0px);
            }
        
            .inputgroup{
                justify-content: center;
                display: flex;
            }
             .focusInput{
                box-shadow: black 1px 2px 1px 1px, rgb(43, 60, 139) 1px 2px 0px 6px, white 1px 2px 0px 5px;
             }
            .nameInput{
                border-radius: 12px;
                border: 2px solid black;
                /* box-shadow: black 1px 2px 1px 1px; */
                outline: none;
                padding: 0.5rem 0.5rem 0.5rem 1rem;
                margin-right: 1rem;
                margin-bottom: 1rem;
                transition: box-shadow 250ms ease 0s;
                -webkit-box-align: center;
                align-items: center;
                box-sizing: border-box;
                display: flex;
                font-family: monospace;
                font-size: 1.5rem;
                height: 4rem;
                -webkit-box-pack: center;
                justify-content: center;
                pointer-events: auto;
                text-align: left;
                width: 14rem;
            }
        
            .EmailInput{
                border-radius: 12px;
                border: 2px solid black;
                box-shadow: black 1px 2px 1px 1px;
                outline: none;
                padding: 0.5rem 0.5rem 0.5rem 1rem;
                margin-right: 1rem;
                margin-bottom: 1rem;
                transition: box-shadow 250ms ease 0s;
                -webkit-box-align: center;
                align-items: center;
                box-sizing: border-box;
                display: flex;
                font-family: monospace;
                font-size: 1.5rem;
                height: 5rem;
                -webkit-box-pack: center;
                justify-content: center;
                pointer-events: auto;
                text-align: left;
                width: 19rem;
            }
            .css-1id8coj {
                background-color: white;
                width: 70vw;
                height: 50%;
                margin: auto;
                overflow-y: auto;
                padding: 1rem;
                border: 2px solid black;
                border-radius: 12px;
                box-shadow: black 1px 2px 1px 1px;
                margin: 63px auto 30px auto;
            }
            .css-z0ieus {
                font-size: 1.5rem;
                color: black;
                text-align: center;
                font-weight: bold;
                text-shadow: none;
                margin: 1rem;
            }
        
            .css-1ldd5ho {
                font-size: 2rem!important;
                text-shadow: black 1px 2px 0px!important;
                color: rgb(252, 173, 20);
                margin-right: 2rem!important;
                opacity: 1;
                transition: opacity 250ms ease-in-out 0s;
                padding: 14px;
            }
        
            .css-17vg6hn {
                position: relative;
                font-size: 1.5rem;
               font-family: inherit;
               font-weight: 500;
                color: inherit;
                line-height: inherit;
                appearance: none;
                min-width: 12em;
                width: 100%;
                background-color: white;
                margin: 0px;
                border-radius: 12px;
                border: 2px solid black;
                padding: 0.5em;
                transition: opacity 250ms ease-in-out 0s;
            }
        
              }



    button:active {
        box-shadow: 0 5px #666;
        transform: translateY(3px);
      }

 .keypadcss:active {
    box-shadow: 0 5px #666!important;
    transform: translateY(3px)!important;
  }   

  .toastimp{
    position: absolute;
    top: 22%;
    right: 15%;
    padding: 10px;
    border-radius: 3px;
    background: rgba(222, 226, 255, 0.85);
    color: red;
    font-size: 20px;
  }

  .mt-20{
margin-top: 20px;
  }
.dobmsg{
    text-shadow: black 0px 0px 0px, black 0px 1px 0px, black 1px 0px 0px, black 1px 1px 0px, black 1px 2px 0px, black 2px 1px 0px, black 2px 2px 0px;
    text-align: center;
    font-weight: bold;
    color: rgb(226, 50, 41);
    opacity: 1;
    margin: 1rem auto;
    font-size: 2rem;
}

.dob-label-color select {
    background-color: #fafafa;
    width: 100%;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 0;
    }
    
    .centerDiv2 {
        margin: auto;
        /* max-height: 129.6px; */
      }

      
        .centerDiv2  img{
        order: 1px solid rgb(255, 255, 255);
        border-radius: 10px;
        max-width: 30vw;
        max-height: 12vh;
        margin: 1vh;
        }



   

       .crossbuttoncss {
            background-color: rgb(226, 50, 41)!important;
    box-shadow: inset 0 0 0 1px rgba(17, 20, 24, .2), 0 1px 2px rgba(17, 20, 24, .1)!important;
    color: #fff!important;
    border: 2px solid black!important;
    border-radius: 12px!important;
    box-shadow: black 1px 2px 1px 1px!important;
    font-size: 1rem!important;
    text-shadow: black 1px 1px 1px!important;
        }
body {
  margin: 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.App::before{
  filter: grayscale(0.5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nextbuttoncss{
  font-family: revert!important;
}

.main-container{
  padding: 4px;
  display: grid;

}

.main-container1{
  padding: 4px;
}

.enterMobile{
  margin: 6px 0px 0px 0px;
  font-size: 2.25rem;
    text-shadow: black 0px 0px 0px, black 0px 1px 0px, black 1px 0px 0px, black 1px 1px 0px, black 1px 2px 0px, black 2px 1px 0px, black 2px 2px 0px;
    text-align: center;
    font-weight: bold;
}
.enterMobilesub{
  font-size: 1.5rem;
    text-shadow: black 0px 0px 0px, black 0px 1px 0px, black 1px 0px 0px, black 1px 1px 0px, black 1px 2px 0px, black 2px 1px 0px, black 2px 2px 0px;
    text-align: center;
    font-weight: bold;
    margin: 7px 0px 0px 0px;
}
.enterMobilesub1{
  font-size: 1rem;
    text-shadow: black 0px 0px 0px, black 0px 1px 0px, black 1px 0px 0px, black 1px 1px 0px, black 1px 2px 0px, black 2px 1px 0px, black 2px 2px 0px;
    text-align: center;
    font-weight: bold;
}
.p-10H-1{
  padding: 7px!important;
 color: rgb(20, 96, 236)!important;
  cursor: pointer;
  
}

.p-10H-1:hover{
  padding: 7px!important;
  color: cornflowerblue!important; 
  cursor: pointer;
  
}


.css-1rarh3x {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 8px auto auto;
  justify-content: center;
  
}
.nextbuttoncssc{
  border-radius:10px!important;
  padding: 0.25em 0.5em!important;
  font-size: 3.5vmin!important;
  font-weight: 400;
  color: #fff;
  border: 2px solid #000;
  box-shadow: black 1px 2px 1px 1px;
  text-shadow:black 1px 1px 1px;
}
@media only screen and (min-device-width : 1000px) and (max-device-width : 4000px) {


  .css-5yk6n {
    box-shadow: black 1px 2px 1px 1px;
    outline: none;
    padding: 0.5rem;
    text-align: center;
    transition: box-shadow 250ms ease 0s;
    font-family: monospace;
    font-weight: bold;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 4rem;
    width: 14rem;
    margin: 0px;
    font-size: 1.5rem;
    border-width: 2px 1px 2px 2px;
    border-style: solid;
    border-color: black;
    border-image: initial;
    border-radius: 12px 0px 0px 12px;
  }
  .css-1ceqh3h {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 2rem;
    text-shadow: black 1px 1px 1px;
    border: 2px solid black;
    border-radius: 0px 12px 12px 0px;
    outline: none;
    box-shadow: black 1px 2px 1px 1px;
    margin: 0px;
    color: white;
    transform: translate(0px, 0px);
    width: 4rem;
    height: 4rem;
    padding: 0.9rem;
  }
  
  .mt-1{
    margin-top: 10px;
  }
  
  .optoutbtn{
    border-radius:10px!important;
    padding: 10px 20px!important;
    font-size: 1.7em!important;
    font-weight: 400;
    color: #fff;
    font-family: revert;
    text-shadow: black 1px 1px 1px;
    border: 2px solid black;
    box-shadow: black 1px 2px 1px 1px;
  }

  .optoutbtnr{
    border-radius:10px!important;
    padding: 12px!important;
    /* font-size: 23px!important; */
    font-weight: 400;
    color: #fff;
    border: 2px solid #000;
  }
  .nextbuttoncss{
    border-radius:10px!important;
    padding: 0.25em 0.5em!important;
    font-size: 4.5vmin!important;
    font-weight: 400;
    color: #fff;
    border: 2px solid #000;
    box-shadow: black 1px 2px 1px 1px;
    text-shadow:black 1px 1px 1px;
  }
  }

  .enterMobile{

      white-space: nowrap!important;
  }



  @media only screen and (min-device-width : 480px) and (max-device-width : 1000px) {


    .css-5yk6n {
      box-shadow: black 1px 2px 1px 1px;
      outline: none;
      padding: 0.5rem;
      text-align: center;
      transition: box-shadow 250ms ease 0s;
      font-family: monospace;
      font-weight: bold;
      box-sizing: border-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      height: 4rem;
      width: 14rem;
      margin: 0px;
      font-size: 1.5rem;
      border-width: 2px 1px 2px 2px;
      border-style: solid;
      border-color: black;
      border-image: initial;
      border-radius: 12px 0px 0px 12px;
    }
    .css-1ceqh3h {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      font-size: 2rem;
      text-shadow: black 1px 1px 1px;
      background: rgb(43, 60, 139);
      border: 2px solid black;
      border-radius: 0px 12px 12px 0px;
      outline: none;
      box-shadow: black 1px 2px 1px 1px;
      margin: 0px;
      color: white;
      transform: translate(0px, 0px);
      width: 4rem;
      height: 4rem;
      padding: 0.9rem;
    }
    
    .mt-1{
      margin-top: 10px;
    }
    
    .optoutbtn{
      /* border-radius:10px!important;
      padding: 12px!important;
      font-size: 23px!important;
      font-weight: 400;
      color: #fff;
      border: 2px solid #000; */
      border-radius:10px!important;
      padding: 10px 20px!important;
      font-size: 1.7em!important; 
      font-weight: 400;
      color: #fff;
      font-family: revert;
      text-shadow: black 1px 1px 1px;
      border: 2px solid black;
      box-shadow: black 1px 2px 1px 1px;
    }
    
    .optoutbtnr{
      border-radius:10px!important;
      padding: 12px!important;
      /* font-size: 23px!important; */
      font-weight: 400;
      color: #fff;
      border: 2px solid #000;
    }
    

.nextbuttoncss{
  border-radius:10px!important;
  padding: 12px!important;
  font-size: 3.5vmin!important;
  font-weight: 400;
  color: #fff;
  border: 2px solid #000;
  box-shadow: black 1px 2px 1px 1px;
  text-shadow:black 1px 1px 1px;
}




    
    }

    /* button:disabled{
      background: rgb(136, 136, 136)!important;
    } */

    .enternameTitle{
      font-size: 3rem;
      text-shadow: black 0px 0px 0px, black 0px 1px 0px, black 1px 0px 0px, black 1px 1px 0px, black 1px 2px 0px, black 2px 1px 0px, black 2px 2px 0px;
      text-align: center;
      font-weight: bold;
  }
  .enternameTitle1{
    font-size: 25px;
    text-shadow: black 0px 0px 0px, black 0px 1px 0px, black 1px 0px 0px, black 1px 1px 0px, black 1px 2px 0px, black 2px 1px 0px, black 2px 2px 0px;
    text-align: center;
    font-weight: bold;
}
  .restartCheckinButton{
    font-size: 3.5vmin!important;
    border-radius: 12px!important;
    position: absolute;
    right: 0px;
    top: 0px;
    align-items: center;
    text-shadow: black 1px 1px 1px;
    border: 2px solid black;
    border-radius: 12px;
    outline: none;
    box-shadow: black 1px 2px 1px 1px;
    margin: 0.5em;
    padding: 0.25em 0.5em!important;
    cursor: pointer;
    transform: translate(0px, 0px);
  }